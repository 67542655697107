import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { AEMPage, EditableButton } from '@fcamna/aem-library';
import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import withAnimatedNavigation, { AnimatedNavigationProps } from '../../../../utils/withAnimatedNavigation';
import { getAEMHost } from '../../config';
import Code from '../Code';
import { EditableRegexInput } from '../EditableInput';
import { Page, PageContent, PageFooter, PageHeader } from '../Page';
import { Text } from '../wrappedLibraryComponents/Text';

const StyledCode = styled(Code)`
  span {
    text-wrap: nowrap;
  }
`;

const DeployingAssembler = (props: AnimatedNavigationProps) => {
  const isInEditor = AuthoringUtils.isInEditor();
  const [instructions, setInstructions] = useState(AuthoringUtils.isInEditor());
  const productNameRef = useRef<HTMLInputElement | null>(null);
  const [isProductNameValid, setIsProductNameValid] = useState(AuthoringUtils.isInEditor());

  const [assemblerLatestVersion, setAssemblerLatestVersion] = useState('');

  useMemo(() => {
    fetch(`${getAEMHost()}${process.env.AEM_CONTENT_BASE}/releases.model.json`)
      .then((res) => res.json())
      .then((model) => {
        const [latest] = Object.keys(model?.[':children'] || {})
          .map((path) => path.replace(`${process.env.AEM_CONTENT_BASE}/releases/`, ''))
          .filter((path) => !path.includes('/'))
          .sort((a, b) => a.localeCompare(b) * -1)
          .map((v) => v.replace(/-/g, '.'));
        setAssemblerLatestVersion(latest);
      });
  }, []);

  return (
    <AEMPage pagePath={`${process.env.AEM_CONTENT_BASE}/provisioning-assembler/deploying-assembler`} aemHost={getAEMHost()}>
      <Page>
        <PageHeader>
          <Text name="header" />
        </PageHeader>
        <PageContent>
          <Text name="p1" />
          <div style={{ width: 75, marginTop: 30, display: 'flex', flexDirection: 'row', alignItems: 'top', gap: 20 }}>
            <EditableRegexInput
              hideLabel={true}
              name="productName"
              className="productName"
              onValidChange={(value: boolean) => setIsProductNameValid(value)}
              regex={/^[a-zA-Z\-\s0-9]+$/}
              inputRef={productNameRef}
            />
            <EditableButton
              isDisabled={!isProductNameValid || !assemblerLatestVersion}
              style={{ marginTop: -5 }}
              className="continueBtn"
              onClick={() => {
                setInstructions(!instructions);
              }}
            />
          </div>
          {(instructions || isInEditor) && (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 20, marginBottom: 20 }}>
              <Text name="variable-block" />
              <StyledCode
                codeBlockProps={{
                  showLineNumbers: true
                }}
                command={`variable dt_cluster_id {
  type        = string
  description = "dynatrace cluster id"
}
variable dt_connection_point{
  type        = string
  description = "dynatrace connection point"
}
variable "assembler_image_prefix" {
  type        = string
  description = "The beginning part of the url required for Artifact Registry images."
}
variable "assembler_image_url" {
  type        = string
  description = "The url of the assembler image with which the service has to be created. The url should be fully qualified url. The image should be in the project's artifact registry. The format is <repo>/<ProjectID>/<ImageName>:<Tag>."
}
variable "assembler_vpc_connector" {
  type        = string
  description = "The VPC Network Connector that this cloud run can connect to. The Cloud Run Service Agent requires access to the host project's VPC connector, see requirements. See https://github.ford.com/gcp/tfm-cloud-run#other-requirements for valid values."
}`}
              />
              <Text name="main-block" />
              <StyledCode
                command={`module "cloud_run_assembler" {
  source                = "git@github.ford.com:gcp/tfm-cloud-run-external.git"
  gcp_project_id        = var.gcp_project_id
  service_name          = "assembler"      # The name of the cloud run service"
  service_image_url     = "\${var.assembler_image_prefix}$\{var.assembler_image_url}"
  # This service account represents the identity of the service and determines what permissions the service has.
  service_account_email = "sa-cldrun@$\{var.gcp_project_id}.iam.gserviceaccount.com"
  # Accounts that can access the cloud run service. You can pass these values as a list of user, serviceAccount and group.
  service_invoker       = ["allUsers"]
  service_vpc_connector = var.service_vpc_connector            # See variables.tf for valid options
  container_port        = 3000          # Port to which container listens. The default value is 8080
  memory_size           = 4096
  cpu_count             = 2
  min_instance_count    = 1
  container_concurrency = 200
  # List of environment variables to set in the container.
  environment = [ 
    {
      name  = "PRODUCT_APP_NAME"
      value = "${productNameRef.current?.value}"
    },
    {
      name = "APP_ENV"
      value = "\${var.app_environment}"
    },
    {
      name  = "LD_PRELOAD"
      value = "/opt/dynatrace/oneagent/agent/lib64/liboneagentproc.so"
    },
    {
      name = "dt_cluster_id"
      value = ""
    },
    {
      name = "dt_connection_point"
      value = ""
    }
  ]
}`}
                codeBlockProps={{
                  showLineNumbers: true
                }}
              />
              <Text name="env-main" />
              <StyledCode
                command={`# Populate application's dynatrace cluster id 
dt_cluster_id           = ""
# Populate application's dynatrace dt_connection_point
dt_connection_point     = ""
assembler_vpc_connector = "projects/prj-p-gxlball-pub-net-a017/locations/us-central1/connectors/prod-gxlball-us-central1"
assembler_image_prefix  = "us-central1-docker.pkg.dev/ford-3f3a4b6bd19e1b2439903ddb/ford-container-images/"
assembler_image_url     = "assembler:${assemblerLatestVersion}"`}
                codeBlockProps={{
                  showLineNumbers: true
                }}
              />
              <Text name="deploy" />
              <StyledCode
                command={`dt_cluster_id           = ""
dt_connection_point     = ""
assembler_vpc_connector = "projects/prj-p-gxlball-pub-net-a017/locations/us-central1/connectors/prod-gxlball-us-central1"
assembler_image_prefix  = "us-central1-docker.pkg.dev/ford-5b0c4eb9072ad7b76b44b966/ford-container-images/"
assembler_image_url     = "assembler:${assemblerLatestVersion}"`}
                codeBlockProps={{
                  showLineNumbers: true
                }}
              />
              <Text name="need-help" />
            </div>
          )}
        </PageContent>
        <PageFooter>
          <EditableButton name="next-page" onClick={() => props.navigateForward!('/provisioning-assembler/further-customizations')} />
        </PageFooter>
      </Page>
    </AEMPage>
  );
};

export default withAnimatedNavigation(DeployingAssembler);
